<!--Pagina de edicion de Foto-->
<template>
    <v-container fill-height id="scroll-target" fluid class="black d-flex align-start justify-center" v-scroll:#scroll-target="onScroll">
        <!--Menu-->
        <v-row class=""  justify="center" no-gutters>
            <v-col cols="12" md="11" class="py-0 px-0" >
                <menus/>
            </v-col>
        </v-row>
        <!--fila contenido central desktop y mobile-->
        <v-row class="justify-center" no-gutters>
            <!--fila hashtags superior desktop y mobile nuevo-->
            <v-col cols="12"  md="12" class="d-flex justify-center mb-3 mb-md-0" >
                <v-responsive class="img1 d-none d-md-flex" max-width="87.2%">
                &nbsp;
                </v-responsive>
                <v-responsive class="img1 d-md-none ">
                &nbsp;
                </v-responsive>
            </v-col>
            <!--columna hashtags izquierda desktop-->
            <v-col  cols="0" md="1" class="d-none d-md-flex">
                <div class="img3">&nbsp;</div>
            </v-col>
            <!--columna hashtags izquierda mobile-->
            <v-col  cols="1" md="0" class="d-md-none">
                <div class="img3">&nbsp;</div>
            </v-col>
            <!--columna de la imagen mobile y desktop-->
            <v-col  cols="10" md="5" class="py-md-3 pl-md-3 d-flex align-center justify-center ">
                <!--Imagen para desktop-->
                <v-responsive align="center"  width="100%" :aspect-ratio="16/9" class="justify-center text-center mx-auto d-none d-md-flex align-center" max-height="600" >
                    <v-img
                        :aspect-ratio="16/9"
                        max-height="600"
                        width="100%"
                        class="align-end justify-center mx-auto"
                        :src= this.img
                    >
                        <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                            </v-row>
                        </template>
                        <!--Contenedor de los stickers y el boton-->
                        <!--:style="i!=4?'transform: translate('+(100*(i))+'px,0px)':'transform: translate(0px,100px)'"-->
                        <div class="contenedor" id="contenedor">
                            <v-img
                                data-x="0"
                                data-y="0"
                                v-for="(item, i) in stickersF"
                                :key="i"
                                :id="'d'+i"
                                aspect-ratio="1"
                                elevation="0"
                                class="text-right draggable sticker"
                                :src="item.name"
                            >
                                <v-chip
                                    color="rgba(0,0,0,0)"
                                    class="ml-n5"
                                    close
                                    @click:close="stickersF.splice(i, 1)"
                                >
                                </v-chip>
                                <v-icon style="position: absolute; bottom: 0; " class="becks--text ml-n6 iconoResize">mdi-resize-bottom-right</v-icon>
                            </v-img>
                            <v-file-input
                                class="d-none"
                                accept="image/png, image/jpeg"
                                id="archivos"
                                v-model="imageData"
                                @change="onUpload()"
                            ></v-file-input>
                        </div>
                    </v-img>
                    <v-btn
                                color="black"
                                elevation="2"
                                class="mb-10 mt-5 mx-auto becks--text bordeado elbtn text-center"
                                min-width="270px"
                                rounded
                                outlined
                                @click="cambiarP('AgregarUnaFoto')"
                            >
                                subir otra foto
                            </v-btn>
                </v-responsive>
                <!--Imagen para mobile-->
                <v-responsive align="center"  width="100%" :aspect-ratio="9/16" class="justify-center text-center mx-auto d-flex d-md-none" max-height="600" style="z-index: 3" >
                    <v-img
                        :aspect-ratio="9/16"
                        max-width="350px"
                        width="100%"
                        class="align-end justify-center mb-2 mx-auto"
                        :src= this.img
                        id="imgMobile"
                    >
                        <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular
                                indeterminate
                                color="becks"
                            ></v-progress-circular>
                            </v-row>
                        </template>
                        <!--Contenedor de los stickers y el boton-->
                        <!--:style="i!=4?'transform: translate('+(100*(i))+'px,0px)':'transform: translate(0px,100px)'"-->
                        <div class="contenedor2" id="contenedor1" >
                            <v-img
                                data-x="0"
                                data-y="0"
                                v-for="(item, i) in stickersF"
                                :key="i"
                                :id="'m'+i"
                                aspect-ratio="1"
                                elevation="0"
                                class="text-right draggable sticker overflow-visible"
                                :src="item.name"
                                style="touch-action: none; user-select: none; z-index: 5"
                            >
                                <v-chip
                                    color="rgba(0,0,0,0)"
                                    class="ml-n5"
                                    close
                                    @click:close="stickersF.splice(i, 1)"
                                >
                                </v-chip>
                                <v-icon style="position: absolute; bottom: 0; " class="becks--text ml-n6 iconoResize">mdi-resize-bottom-right</v-icon>
                            </v-img>
                            <v-file-input
                                class="d-none"
                                accept="image/png, image/jpeg"
                                id="archivos"
                                v-model="imageData"
                                @change="onUpload()"
                            ></v-file-input>
                        </div>

                    </v-img>
                    <v-btn
                        color="black"
                        elevation="2"
                        class="mb-10 becks--text bordeado text-center"
                        min-width="270px"
                        rounded
                        outlined
                        @click="cambiarP('AgregarUnaFoto')"
                    >
                        subir otra foto
                    </v-btn>
                </v-responsive>
            </v-col>
            <!--columna hashtags derecha mobile-->
            <v-col  cols="1" md="0" class="d-md-none">
                <div class="img4"></div>
            </v-col>
            <!--columna hashtags abajo mobile-->
            <v-col cols="12"  md="0" class="d-md-none">
                <div class="img22 d-md-none d-flex justify-end">
                &nbsp;
                <!--Boton  de scroll mobile-->
                <v-btn fab class="becks site-nav" bottom rigth fixed @click="$vuetify.goTo(9999)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                </div>
            </v-col>
            <!--columna hashtags abajo desktop-->
            <v-col  cols="12" md="5" class="py-md-3 pr-md-3" align-self="center">
                <v-card align="center" color="rgba(255,255,255,0.2)" class="text-center  align-start">
                <v-responsive  align="center"  width="100%" class="justify-center text-center mx-auto align-center tam">
                    <h2 class="white--text mb-5 pt-3" style="font-size: 110%">PERSONALIZA TU FOTO</h2>
                    <!--Botones para Desktop-->
                    <v-card rounded="xl" color="rgba(255,255,255,0.20)" class="pa-2 mx-auto my-md-1 d-none d-md-block" >
                        <v-row no-gutters>
                            <!-- <v-col cols="4">
                                <v-btn color="white" block  elevation="2" class="rounded-lg" max-width="" large v-if="tab == 0"  @click="tab = 0">
                                    <span class="textoR">FONDOS</span>
                                </v-btn>
                                <v-btn color="" block dark text elevation="0" class="rounded-lg" max-width="" large  tile v-else  @click="tab = 0">
                                    <span class="textoR">FONDOS</span>
                                </v-btn>
                            </v-col> -->
                            <v-col cols="6">
                                <v-btn color="white" block  elevation="0" class="rounded-lg" max-width="" large tile v-if="tab == 0" @click="tab = 0">
                                    <span class="textoR">FILTROS</span>
                                </v-btn>
                                <v-btn color="" block text dark elevation="0" class="rounded-lg" max-width="" large tile v-else @click="tab = 0">
                                    <span class="textoR">FILTROS</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn color="white" block  elevation="0" class="rounded-lg" max-width="" large tile v-if="tab == 1" @click="tab = 1">
                                    <span class="textoR">STICKERS</span>
                                </v-btn>
                                <v-btn color="" block text dark elevation="0" class="rounded-lg" max-width="" large tile v-else @click="tab = 1">
                                    <span class="textoR">STICKERs</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-tabs-items v-model="tab" class="d-none d-md-flex transp white--text justify-center">
                        <!-- <v-tab-item > -->
                            <!--Empieza la tarjeta de fondos desktop-->
                            <!-- <v-responsive class="mt-5 ml-3 justify-center" min-width="500px" >
                                <v-row class="" wrap >
                                    <v-col cols="2">
                                        <v-responsive
                                        elevation="1"
                                        aspect-ratio="1"
                                        class="rounded-circle justify-center align-center d-flex grey pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,0)"
                                        >
                                        <v-icon x-large>mdi-close</v-icon>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,1)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_1.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,2)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_2.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,3)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_3.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,4)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_4.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,5)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_5.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,6)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_6.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,7)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_7.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,8)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_8.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,9)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_9.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,10)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_10.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,11)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_11.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,12)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_12.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,13)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_13.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,14)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_14.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,15)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_15.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,16)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_16.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,17)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_17.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,18)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_18.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle justify-center align-center d-flex pointer"
                                        width="80%"
                                        @click="cambiarFondo(2,19)"
                                        >
                                            <v-img
                                                src="../assets/mini/iconosFondos_19.png"
                                                class="rounded-circle"
                                                width="100%"
                                            ></v-img>
                                        </v-responsive>
                                    </v-col>
                                </v-row>
                            </v-responsive> -->
                        <!-- </v-tab-item> -->
                        <!--Empieza la tarjeta de filtros desktop-->
                        <v-tab-item>
                            <!--Empieza la tarjeta de filtros desktop-->
                            <v-responsive width="100%"  class="mt-5 overflow-y-auto justify-start" min-width="500px" max-height="350px">
                                <v-row class="" wrap>
                                    <v-col cols="2">
                                        <v-responsive
                                        elevation="1"
                                        aspect-ratio="1"
                                        class="rounded-circle justify-center align-center d-flex grey pointer"
                                        width="100%"
                                        @click="colorImagen(0,0,0,2)"
                                        >
                                        <v-icon x-large>mdi-close</v-icon>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        color="white"
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle white pointer"
                                        width="100%"
                                        @click="colorImagen(55,55,55,2)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle red pointer"
                                        width="100%"
                                        @click="colorImagen(254, 76, 65,2)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        class="rounded-circle green pointer"
                                        elevation="1"
                                        width="100%"
                                        @click="colorImagen(0, 131, 0,2)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        class="rounded-circle blue pointer"
                                        elevation="1"
                                        width="100%"
                                        @click="colorImagen(50, 50, 222 ,2)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-responsive
                                        aspect-ratio="1"
                                        class="rounded-circle yellow pointer"
                                        elevation="1"
                                        width="100%"
                                        @click="colorImagen(110, 110, 0,2)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in filtros"
                                        :key="i"
                                    >
                                        {{item.nombre}}
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.foto"
                                            @click="cambiarFiltro(2,item.filtro);"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                            </v-responsive>
                        </v-tab-item>
                        <!--Empieza la tarjeta de stickers desktop-->
                        <v-tab-item class="">
                            <!--Empieza la tarjeta de stickers desktop-->
                            <v-responsive  class="mt-5 overflow-y-auto justify-start" min-width="500px" max-height="350px">
                                <!-- Fila stickers 80s-->
                                <v-spacer class="mt-3 mb-3">
                                    80's
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers1"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))) : console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers Cottagecore-->
                                <v-spacer class="mt-3 mb-3">
                                    COTTAGECORE
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers2"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers Fitness-->
                                <v-spacer class="mt-3 mb-3">
                                    FITNESS
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers3"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers Futurist-->
                                <v-spacer class="mt-3 mb-3">
                                    FUTURIST
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers4"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers gaming-->
                                <v-spacer class="mt-3 mb-3">
                                    GAMING
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers5"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers LO-FI-->
                                <v-spacer class="mt-3 mb-3">
                                    LO-FI
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers6"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers LO-FI-->
                                <v-spacer class="mt-3 mb-3">
                                    NO PLANET B
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers7"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers LO-FI-->
                                <v-spac>
                                    PINK POP
                                </v-spac>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers8"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers Pride-->
                                <v-spacer class="mt-3 mb-3">
                                    PRIDE
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers9"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers Punk Rock-->
                                <v-spacer class="mt-3 mb-3">
                                    PUNK ROCK
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers10"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers SpaceCore-->
                                <v-spacer class="mt-3 mb-3">
                                    SPACECORE
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers11"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <!-- Fila stickers Trippy Hypebeast-->
                                <v-spacer class="mt-3 mb-3">
                                    TRIPPY HYPEBEAST
                                </v-spacer>
                                <v-row class="" wrap>
                                    <v-col 
                                        cols="2"
                                        v-for="(item, i) in stickers12"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                </v-row>
                            </v-responsive>
                        </v-tab-item>
                    </v-tabs-items>
                    <!--Botones para mobile-->
                    <v-card rounded="lg" color="rgba(255,255,255,0.0)" class="pa-2 mx-md-3 my-md-1 d-md-none" >
                        <v-row dense>
                            <!-- <v-col cols="4">
                                <v-btn color="white" block  elevation="2" class="rounded-pill" max-width="" large v-if="tab == 0"  @click="tab = 0">
                                    <span class="textoR">FONDOS</span>
                                </v-btn>
                                <v-btn style="background-color: rgba(255,255,255,0.20)" block dark text elevation="0" class="rounded-pill" max-width="" large  tile v-else  @click="tab = 0">
                                    <span class="textoR">FONDOS</span>
                                </v-btn>
                            </v-col> -->
                            <v-col cols="6">
                                <v-btn color="white" block  elevation="0" class="rounded-pill" max-width="" large tile v-if="tab == 0" @click="tab = 0">
                                    <span class="textoR">FILTROS</span>
                                </v-btn>
                                <v-btn style="background-color: rgba(255,255,255,0.20)" block text dark elevation="0" class="rounded-pill" max-width="" large tile v-else @click="tab = 0">
                                    <span class="textoR">FILTROS</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn color="white" block  elevation="0" class="rounded-pill" max-width="" large tile v-if="tab == 1" @click="tab = 1">
                                    <span class="textoR">STICKERS</span>
                                </v-btn>
                                <v-btn style="background-color: rgba(255,255,255,0.20)" block text dark elevation="0" class="rounded-pill" max-width="" large tile v-else @click="tab = 1">
                                    <span class="textoR">STICKERs</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card rounded="rounded-b-xl rounded-t-0" color="rgba(0,0,0,0)" class="mt-3" max-height="150px" elevation="0">
                            <v-tabs-items v-model="tab" class="transp overflow-x-auto rounded-0" touchless>
                                <!--Empieza la tarjeta de fondos mobile-->
                                <!-- <v-tab-item transition="false"> -->
                                    <!-- <v-row class="flex-nowrap" nowrap dense>
                                        <v-col cols="3">
                                            <v-responsive
                                            elevation="1"
                                            aspect-ratio="1"
                                            class="rounded-circle justify-center align-center d-flex grey"
                                            width="100%"
                                            @click=" cambiarFondo(2,0)"
                                            >
                                            <v-icon x-large>mdi-close</v-icon>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,1)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_1.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,2)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_2.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,3)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_3.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,4)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_4.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,5)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_5.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,6)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_6.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,7)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_7.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,8)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_8.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,9)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_9.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,10)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_10.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,11)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_11.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,12)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_12.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,13)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_13.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,14)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_14.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,15)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_15.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,16)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_16.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,17)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_17.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,18)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_18.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-responsive
                                            aspect-ratio="1"
                                            elevation="1"
                                            class="rounded-circle justify-center align-center d-flex pointer"
                                            width="100%"
                                            @click=" cambiarFondo(1,19)"
                                            >
                                                <v-img
                                                    src="../assets/mini/iconosFondos_19.png"
                                                    class="rounded-circle"
                                                    width="100%"
                                                ></v-img>
                                            </v-responsive>
                                        </v-col>
                                    </v-row> -->
                                <!-- </v-tab-item> -->
                                <!--Empieza la tarjeta de filtros mobile-->
                                <v-tab-item transition="false">
                                    <v-row class="flex-nowrap" nowrap dense>
                                    <v-col cols="3">
                                        <v-responsive
                                        elevation="1"
                                        aspect-ratio="1"
                                        class="rounded-circle justify-center align-center d-flex grey pointer"
                                        width="100%"
                                        @click=" colorImagen(0,0,0,1)"
                                        >
                                        <v-icon x-large>mdi-close</v-icon>
                                        </v-responsive>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-responsive
                                        color="white"
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle white pointer"
                                        width="100%"
                                        @click=" colorImagen(55,55,55,1)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-responsive
                                        aspect-ratio="1"
                                        elevation="1"
                                        class="rounded-circle red pointer"
                                        width="100%"
                                        @click=" colorImagen(255,0,0,1)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-responsive
                                        aspect-ratio="1"
                                        class="rounded-circle green pointer"
                                        elevation="1"
                                        width="100%"
                                        @click=" colorImagen(0,155,0,1)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-responsive
                                        aspect-ratio="1"
                                        class="rounded-circle blue pointer"
                                        elevation="1"
                                        width="100%"
                                        @click=" colorImagen(0,0,255,1)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-responsive
                                        aspect-ratio="1"
                                        class="rounded-circle yellow pointer"
                                        elevation="1"
                                        width="100%"
                                        @click=" colorImagen(55,55,0,1)"
                                        ></v-responsive>
                                    </v-col>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in filtros"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.foto"
                                            @click=" cambiarFiltro(1,item.filtro);"
                                        >
                                        </v-img>
                                    </v-col>
                                    </v-row>
                                </v-tab-item>
                                <!--Empieza la tarjeta de stickers mobile-->
                                <v-tab-item transition="false">
                                    <v-tabs
                                        fixed-tabs
                                        background-color="rgba(0,0,0,0)"
                                        center-active
                                        dark
                                        class="overflow-x-auto"
                                    >
                                        <v-tab @click="tabSticker = 0">80's</v-tab>
                                        <v-tab @click="tabSticker = 1">COTTAGECORE</v-tab>
                                        <v-tab @click="tabSticker = 2">FITNESS</v-tab>
                                        <v-tab @click="tabSticker = 3">FUTURIST</v-tab>
                                        <v-tab @click="tabSticker = 4">GAMING</v-tab>
                                        <v-tab @click="tabSticker = 5">LO-FI</v-tab>
                                        <v-tab @click="tabSticker = 6">NO PLANET B</v-tab>
                                        <v-tab @click="tabSticker = 7">PINK POP</v-tab>
                                        <v-tab @click="tabSticker = 8">PRIDE</v-tab>
                                        <v-tab @click="tabSticker = 9">PUNK ROCK</v-tab>
                                        <v-tab @click="tabSticker = 10">SPACECORE</v-tab>
                                        <v-tab @click="tabSticker = 11">TRIPPY HYPEBEAST</v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="tabSticker" class="transp overflow-x-auto" touchless>
                                        <!-- Fila stickers 80s-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                                <v-col 
                                                    cols="3"
                                                    v-for="(item, i) in stickers1"
                                                    :key="i"
                                                >
                                                    <v-img
                                                        aspect-ratio="1"
                                                        width="100%"
                                                        class="pointer"
                                                        :src="item.name.replace('.png', '-1.png')"
                                                        @click="aviso(); aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                                    >
                                                    </v-img>
                                                </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers Cottagecore-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers2"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers Fitness-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers3"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers Futurist-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers4"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers gaming-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers5"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers LO-FI-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers6"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers LO-FI-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers7"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers LO-FI-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers8"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers Pride-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers9"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers Punk Rock-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers10"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers SpaceCore-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers11"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')"
                                            @click="aviso();  stickersF.length <= 4 ? stickersF.push(JSON.parse(JSON.stringify(item))): console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                <!-- Fila stickers Trippy Hypebeast-->
                                        <v-tab-item >
                                            <v-row class="flex-nowrap" nowrap dense>
                                    <v-col 
                                        cols="3"
                                        v-for="(item, i) in stickers12"
                                        :key="i"
                                    >
                                        <v-img
                                            aspect-ratio="1"
                                            width="100%"
                                            class="pointer"
                                            :src="item.name.replace('.png', '-1.png')" 
                                            @click="aviso();  stickersF.length <= 4 ?stickersF.push(JSON.parse(JSON.stringify(item))) : console.log('lleno :c')"
                                        >
                                        </v-img>
                                    </v-col>
                                            </v-row>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </v-card>
                    <!--Botón de publicar mobile-->
                    <v-card class="mx-auto px-5 transp d-md-none" max-width="550" elevation="0">
                        <v-btn
                            color="becks"
                            elevation="2"
                            class="rounded-lg my-5 "
                            block
                            max
                            x-large
                            @click="terminar('contenedor1','m')"
                        >
                            Publicar &nbsp;<v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                    </v-card>
                    <!--Botón de publicar web-->
                    <v-card class="mx-auto px-5 transp d-none d-md-flex" max-width="550" elevation="0">
                        <v-btn
                            color="becks"
                            elevation="2"
                            class="rounded-lg my-5 "
                            block
                            max
                            x-large
                            @click="terminar('contenedor','d')"
                        >
                            Publicar &nbsp;<v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                    </v-card>
                    <!--Empieza el modal de filtros para mobile-->
                </v-responsive>
                </v-card>
            </v-col>
            <!--columna hashtags derecha desktop-->
            <v-col  cols="0" md="1" class="d-none d-md-flex">
                <div class="img4"></div>
            </v-col>
            <!--columna hashtags abajo desktop nuevo-->
            <v-col cols="0"  md="12" class="d-none d-md-flex justify-center">
                <v-responsive class="img2"  max-width="87.2%">
                &nbsp;
                </v-responsive>
            </v-col>
        </v-row>
        <v-row class="justify-center align-align my-3 yellow" no-gutters>
            <!--Empieza el bottom sheet para el aviso si es mobile-->
            <v-bottom-sheet v-model="sheet" v-if="sheet" persistent>
                <v-sheet
                    class="text-center rounded-t-xl becks"
                    width="100%"
                >
                    <v-responsive  aspect-ratio="1" class="text-center becks justify-center rounded-t-xl">
                        <v-card-actions>
                            <v-spacer class="mt-3 mb-3"/>
                            <v-btn
                                icon
                                color="black"
                                class="mr-n1 mb-n2"
                                @click="cambiarP('FotoTerminada')"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="mb-15">
                            <v-spacer class="mt-3 mb-3"/>
                                <v-icon size="80px" color="black" class="becks">fa fa-eye</v-icon>
                            <v-spacer class="mt-3 mb-3"/>
                        </v-card-actions>
                        <h5 class="text-center justify-center ">
                            ESTAMOS REVISANDO SI TU FOTO CUMPLE CON NUESTROS TyC.
                        </h5>
                        <h3 class="text-center justify-center mt-10 mx-8 mb-15">
                            ¡PUEDE SER SELECCIONADA PARA APARECER EN NUESTRAS REDES Y MEDIOS!
                        </h3>
                    </v-responsive>
                </v-sheet>
            </v-bottom-sheet>
            <!--Empieza el modal para el aviso si es web-->
            <v-dialog
                v-model="dialogweb"
                persistent
                max-width="600"
                >
                <v-card 
                    class="becks"
                    rounded="xl"
                    max-width="600"
                >
                    <v-responsive :aspect-ratio="2/1" class="text-center justify-center ">
                        <v-card-actions>
                            <v-spacer class="mt-3 mb-3"/>
                            <v-btn
                                icon
                                color="black"
                                class="mr-n1 mb-n2"
                                @click="cambiarP('FotoTerminada')"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="mb-5">
                            <v-spacer class="mt-3 mb-3"/>
                                <v-icon size="80px" color="black" class="becks">fa fa-eye</v-icon>
                            <v-spacer class="mt-3 mb-3"/>
                        </v-card-actions>
                        <h5 class="text-center justify-center ">
                            ESTAMOS REVISANDO SI TU FOTO CUMPLE CON NUESTROS TyC.
                        </h5>
                        <h3 class="text-center justify-center mt-10 mx-7 mb-5">
                            ¡PUEDE SER SELECCIONADA PARA APARECER EN NUESTRAS REDES Y MEDIOS!
                        </h3>
                    </v-responsive>
                </v-card>
            </v-dialog>
        </v-row>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="becks"
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar
            centered
            dark
            color="becks"
            class=" d-flex justify-center"
            v-model="snackbar"
            :timeout="timeout"
            >
            <span class=" d-flex justify-center text-center black--text">PUEDES ARRASTRAR EL STICKER Y CAMBIAR EL TAMAÑO DESDE LA ESQUINA</span>
        </v-snackbar>
        
    </v-container>
</template>

<script>
    import menus from '../components/menus.vue'
    import axios from "axios"
    //import interact from 'interactjs'

    export default {
        data: () => ({
            //listado de filtros disponibles
            filtros:[
                { foto: 'filtros/01.png', nombre: 'Brillo +', filtro: 1},
                { foto: 'filtros/02.png', nombre: 'Brillo -', filtro: 2},
                { foto: 'filtros/03.png', nombre: 'Cont. -', filtro: 3},
                { foto: 'filtros/04.png', nombre: 'Cont. +', filtro: 4},
                { foto: 'filtros/05.png', nombre: 'Enf', filtro: 5},
                { foto: 'filtros/06.png', nombre: 'Desenf', filtro: 6},
                { foto: 'filtros/07.png', nombre: 'Movimi.', filtro: 7},
                { foto: 'filtros/08.png', nombre: 'Verde', filtro: 8},
                { foto: 'filtros/09.png', nombre: 'Dorado', filtro: 9},
                { foto: 'filtros/10.png', nombre: 'Sepia', filtro: 10},
                { foto: 'filtros/11.png', nombre: 'Grises', filtro: 11},
                { foto: 'filtros/12.png', nombre: 'Negat.', filtro: 12},
                { foto: 'filtros/13.png', nombre: 'Realzar+', filtro: 13},
                { foto: 'filtros/14.png', nombre: 'Frio', filtro: 14},
                { foto: 'filtros/15.png', nombre: 'Realzar-', filtro: 15},
                { foto: 'filtros/16.png', nombre: 'Borde', filtro: 16},
                { foto: 'filtros/17.png', nombre: 'Suavi.', filtro: 17},
                { foto: 'filtros/18.png', nombre: 'Dibujo', filtro: 18},
                { foto: 'filtros/19.png', nombre: 'D. Neg.', filtro: 19},
                { foto: 'filtros/20.png', nombre: 'Pixel', filtro: 20},
                { foto: 'filtros/21.png', nombre: 'Viñeta 1', filtro: 21},
                { foto: 'filtros/22.png', nombre: 'Viñeta 2', filtro: 22},
                // { foto: 'filtros/23.png', nombre: 'Viñeta 3', filtro: 23},
                
            ],
            //Variable de stickers elegidos
            stickersF: [
                
            ],
            //variables de los diferentes grupos de stickers
            stickers1: [
                // { name: 'stickers/80s-Animado.gif', id: '80s11', x: '0', y:'0', g:'1'},
                { name: 'stickers/80s-01.png', id: '80s1', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-02.png', id: '80s2', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-03.png', id: '80s3', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-04.png', id: '80s4', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-05.png', id: '80s5', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-06.png', id: '80s6', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-07.png', id: '80s7', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-08.png', id: '80s8', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-09.png', id: '80s9', x: '0', y:'0', g:'0'},
                { name: 'stickers/80s-10.png', id: '80s10', x: '0', y:'0', g:'0'},
            ],
            stickers2: [
                // { name: 'stickers/Cottagecore-Animado.gif', id: 'Cot11', x: '0', y:'0', g:'1'},
                { name: 'stickers/CottageCore-01.png', id: 'Cot1', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-02.png', id: 'Cot2', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-03.png', id: 'Cot3', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-04.png', id: 'Cot4', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-05.png', id: 'Cot5', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-06.png', id: 'Cot6', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-07.png', id: 'Cot7', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-08.png', id: 'Cot8', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-09.png', id: 'Cot9', x: '0', y:'0', g:'0'},
                { name: 'stickers/CottageCore-10.png', id: 'Cot10', x: '0', y:'0', g:'0'},
            ],
            stickers3: [
                // { name: 'stickers/Fitness-Animado.gif', id: 'Fit11', x: '0', y:'0', g:'1'},
                { name: 'stickers/Fitness-01.png', id: 'Fit1', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-02.png', id: 'Fit2', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-03.png', id: 'Fit3', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-04.png', id: 'Fit4', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-05.png', id: 'Fit5', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-06.png', id: 'Fit6', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-07.png', id: 'Fit7', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-08.png', id: 'Fit8', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-09.png', id: 'Fit9', x: '0', y:'0', g:'0'},
                { name: 'stickers/Fitness-10.png', id: 'Fit10', x: '0', y:'0', g:'0'},
            ],
            stickers4: [
                // { name: 'stickers/Futurist-Animado.gif', id: 'Fut11', x: '0', y:'0', g:'1'},
                { name: 'stickers/FUTURIST-01.png', id: 'Fut1', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-02.png', id: 'Fut2', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-03.png', id: 'Fut3', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-04.png', id: 'Fut4', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-05.png', id: 'Fut5', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-06.png', id: 'Fut6', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-07.png', id: 'Fut7', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-08.png', id: 'Fut8', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-09.png', id: 'Fut9', x: '0', y:'0', g:'0'},
                { name: 'stickers/FUTURIST-10.png', id: 'Fut10', x: '0', y:'0', g:'0'},
                
            ],
            stickers5: [
                // { name: 'stickers/Gaming-Animado.gif', id: 'Gam11', x: '0', y:'0', g:'1'},
                { name: 'stickers/Gaming-01.png', id: 'Gam1', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-02.png', id: 'Gam2', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-03.png', id: 'Gam3', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-04.png', id: 'Gam4', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-05.png', id: 'Gam5', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-06.png', id: 'Gam6', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-07.png', id: 'Gam7', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-08.png', id: 'Gam8', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-09.png', id: 'Gam9', x: '0', y:'0', g:'0'},
                { name: 'stickers/Gaming-10.png', id: 'Gam10', x: '0', y:'0', g:'0'},
            ],
            stickers6: [
                // { name: 'stickers/Lo-fi-animado.gif', id: 'Lof11', x: '0', y:'0', g:'1'},
                { name: 'stickers/Lo-fi-01.png', id: 'Lof1', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-02.png', id: 'Lof2', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-03.png', id: 'Lof3', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-04.png', id: 'Lof4', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-05.png', id: 'Lof5', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-06.png', id: 'Lof6', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-07.png', id: 'Lof7', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-08.png', id: 'Lof8', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-09.png', id: 'Lof9', x: '0', y:'0', g:'0'},
                { name: 'stickers/Lo-fi-10.png', id: 'Lof10', x: '0', y:'0', g:'0'},
            ],
            stickers7: [
                // { name: 'stickers/NoPlanetB-Animado.gif', id: 'Nop11', x: '0', y:'0', g:'1'},
                { name: 'stickers/NoPlanet B-01.png', id: 'Nop1', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-02.png', id: 'Nop2', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-03.png', id: 'Nop3', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-04.png', id: 'Nop4', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-05.png', id: 'Nop5', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-06.png', id: 'Nop6', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-07.png', id: 'Nop7', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-08.png', id: 'Nop8', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-09.png', id: 'Nop9', x: '0', y:'0', g:'0'},
                { name: 'stickers/NoPlanet B-10.png', id: 'Nop10', x: '0', y:'0', g:'0'},
            ],
            stickers8: [
                // { name: 'stickers/PinkPop-Animado.gif', id: 'Pin11', x: '0', y:'0', g:'1'},
                { name: 'stickers/Pink Pop-01.png', id: 'Pin1', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-02.png', id: 'Pin2', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-03.png', id: 'Pin3', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-04.png', id: 'Pin4', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-05.png', id: 'Pin5', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-06.png', id: 'Pin6', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-07.png', id: 'Pin7', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-08.png', id: 'Pin8', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-09.png', id: 'Pin9', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pink Pop-10.png', id: 'Pin10', x: '0', y:'0', g:'0'},
            ],
            stickers9: [
                // { name: 'stickers/Pride-Animado.gif', id: 'Pri11', x: '0', y:'0', g:'1'},
                { name: 'stickers/Pride-01.png', id: 'Pri1', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-02.png', id: 'Pri2', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-03.png', id: 'Pri3', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-04.png', id: 'Pri4', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-05.png', id: 'Pri5', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-06.png', id: 'Pri6', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-07.png', id: 'Pri7', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-08.png', id: 'Pri8', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-09.png', id: 'Pri9', x: '0', y:'0', g:'0'},
                { name: 'stickers/Pride-10.png', id: 'Pri10', x: '0', y:'0', g:'0'},
            ],
            stickers10: [
                // { name: 'stickers/Punk-Rock-Animado.gif', id: 'Pun11', x: '0', y:'0', g:'1'},
                { name: 'stickers/PUNK ROCK-01.png', id: 'Pun1', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-02.png', id: 'Pun2', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-03.png', id: 'Pun3', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-04.png', id: 'Pun4', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-05.png', id: 'Pun5', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-06.png', id: 'Pun6', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-07.png', id: 'Pun7', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-08.png', id: 'Pun8', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-09.png', id: 'Pun9', x: '0', y:'0', g:'0'},
                { name: 'stickers/PUNK ROCK-10.png', id: 'Pun10', x: '0', y:'0', g:'0'},
            ],
            stickers11: [
                // { name: 'stickers/Spacecore-Animado.gif', id: 'Spa11', x: '0', y:'0', g:'1'},
                { name: 'stickers/Spacecore-01.png', id: 'Spa1', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-02.png', id: 'Spa2', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-03.png', id: 'Spa3', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-04.png', id: 'Spa4', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-05.png', id: 'Spa5', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-06.png', id: 'Spa6', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-07.png', id: 'Spa7', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-08.png', id: 'Spa8', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-09.png', id: 'Spa9', x: '0', y:'0', g:'0'},
                { name: 'stickers/Spacecore-10.png', id: 'Spa10', x: '0', y:'0', g:'0'},
            ],
            stickers12: [
                // { name: 'stickers/Trippy-Animado.gif', id: 'Tri11', x: '0', y:'0', g:'1'},
                { name: 'stickers/Trippy hypebeast-01.png', id: 'Tri1', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-02.png', id: 'Tri2', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-03.png', id: 'Tri3', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-04.png', id: 'Tri4', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-05.png', id: 'Tri5', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-06.png', id: 'Tri6', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-07.png', id: 'Tri7', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-08.png', id: 'Tri8', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-09.png', id: 'Tri9', x: '0', y:'0', g:'0'},
                { name: 'stickers/Trippy hypebeast-10.png', id: 'Tri10', x: '0', y:'0', g:'0'},
            ],
            posi: "",
            imagenFondo: "0",
            tipoDispositivo: "0",
            overlay: false,
            imgname: "",
            imageData: "",
            sheet: false,
            dialogweb: false,
            dialog: false,
            pokenombre: "",
            tab: 0,
            tabSticker: 0,
            respuesta: "",
            img: "",
            snackbar: false,
            timeout: 2000,
            offsetTop: 0,
        }),
        created(){
            this.img = "/imagen/archivosTemporales/"+ sessionStorage.nombreImagen+"."+sessionStorage.tipoArchivo
            this.imgname = sessionStorage.nombreImagen
            this.checkCookie();
            
        },
        methods: {
            onScroll (e) {
                this.offsetTop = e.target.scrollTop
                console.log('contador: '+this.offsetTop)
            },
            aviso(){
                this.snackbar = true
            },
            //Función ejecutada para terminar la participación 
            async terminar(id,tipoDispositivo){
                this.overlay = !this.overlay //Activa el overlay de carga
                //Recorrido del array de los stickers elegidos
                this.stickersF.forEach((item, i) => {
                    item.x=this.obtenerX(i,tipoDispositivo);
                    item.y=this.obtenerY(i,tipoDispositivo);
                    item.alto = this.obtenerAlto(i,tipoDispositivo)
                    item.ancho = this.obtenerAncho(i,tipoDispositivo)
                });
                //Verificación del tipo de dispositivo mobile = 1, desktop = 2
                if(tipoDispositivo=="m"){
                    tipoDispositivo= 1
                }else{
                    tipoDispositivo= 2
                }
                var contenedor = document.getElementById(id);
                //Objeto con la información de los stickers para el procesamiento de la imagen
                var json = {
                        'tipoDispositivo': tipoDispositivo,
                        'fondo': sessionStorage.nombreImagen ,
                        'anchoContenedor':   contenedor.clientWidth,
                        'altoContenedor': contenedor.clientHeight,
                        'stickers': this.stickersF 
                    }
                json = JSON.stringify(json)
                    let header = { headers: {'Content-Type': 'application/json'} }

                    //Petición Axios para enviar la información de la imagen y procesarla
                    await axios.post('/imagen/terminar.php', json , header).then(response => {
                            sessionStorage.imagenFinalTipo = response.data.tipo
                            sessionStorage.playlist = response.data.playlist
                            sessionStorage.playlistNombre = response.data.playlistNombre
                        if(response.data.tipo == '1'){
                            sessionStorage.imagenfinalJpeg = response.data.img1
                        }else{
                            sessionStorage.imagenFinalGif = response.data.img1
                            sessionStorage.videoFinalMp4 = response.data.img2
                        }
                        this.overlay = !this.overlay
                        if(tipoDispositivo==1){
                            this.sheet = !this.sheet
                        }else{
                            this.dialogweb = !this.dialogweb
                        }
                    }).catch(error => {
                        this.overlay = !this.overlay
                        // console.log(error);
                    })
            },
            //Obtiene datos de la posición de los stickers yla información del alto y ancho del contenedor
            obtenerX(id,t){
                var x = parseFloat(document.getElementById(t+id).getAttribute('data-x')).toFixed(2)
                // console.log('elemento '+id + 'x: '+x)
                return  x
            },
            obtenerY(id,t){
                var y = parseFloat(document.getElementById(t+id).getAttribute('data-y')).toFixed(2)
                // console.log('elemento '+id + 'y: '+y)
                return  y
            },
            obtenerAncho(id,t){
                return document.getElementById(t+id).clientWidth
            },
            obtenerAlto(id,t){
                return document.getElementById(t+id).clientHeight
            },
            doClick(tipoDispositivo) {//función del botón para dar clic al input de archivos
                this.tipoDispositivo = tipoDispositivo
                var el = document.getElementById("archivos")
                if (el) {
                    el.click();
                }
            },
            colorImagen(r,g,b,tipoDispositivo){//Función para los filtros de color
                this.img =  '/imagen/procesarImagen.php?tipoArchivo='+sessionStorage.tipoArchivo+'&imagenFondo='+this.imagenFondo+'&tipoDispositivo='+tipoDispositivo+'&accion=0&red='+r+'&green='+g+"&blue="+b +"&img=" + this.imgname
            },
            cambiarFondo(tipoDispositivo,imagenFondo){//Función para cambiar los fondos
                this.imagenFondo = imagenFondo
                this.img =  '/imagen/procesarImagen.php?tipoArchivo='+sessionStorage.tipoArchivo+'&imagenFondo='+this.imagenFondo+'&tipoDispositivo='+tipoDispositivo+'&accion=1&img=' + this.imgname
            },
            cambiarFiltro(tipoDispositivo,filtro){//Función para aplicar los filtros personalizados
                this.img =  '/imagen/procesarImagen.php?tipoArchivo='+sessionStorage.tipoArchivo+'&imagenFondo='+this.imagenFondo+'&tipoDispositivo='+tipoDispositivo+'&accion=2&filtro='+filtro+"&img=" + this.imgname
            },
            cambiarP(url){//Cambia de pagina
                axios.get('/').then(response => (
                    this.$router.push({name: url})
                ))
                .catch(
                    // error => console.log('Hubo un error: ' + error)
                )
            },
            checkCookie(){
                axios
                    .post("check_cookie.php", {
                        
                    })
                    .then(function (response) {
                        // console.log(response.data.message)
            
                        if(response.data.message != 'galleta'){
                        window.location.replace("/Edad");
                        }
            
                    })
                    .catch((error) => {
                        //   console.log(error)
                    });
            }
        },
        computed:{

        },
        name: 'Foto',
        components: {
            menus
        },
        mounted(){
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            var tipoDispositivo = width > 960 ? '2' : '1'//si es desktop = 2 y mobile = 1
            var elemento = tipoDispositivo == 2 ? document.getElementById("contenedor") : document.getElementById("contenedor1")
            var altoMinimo = tipoDispositivo == 2 ? elemento.clientHeight * 0.2082 : elemento.clientHeight * 0.37 
            var anchoMinimo = tipoDispositivo == 2 ? elemento.clientWidth * 0.2082 : elemento.clientWidth * 0.37
            let scrollPos = 0;
            //script para ocultar el boton
            const nav = document.querySelector('.site-nav');

            function checkPosition() {
            let windowY = window.scrollY;
            if (windowY < scrollPos) {
                // Scrolling UP
                nav.classList.add('is-visible');
                nav.classList.remove('is-hidden');
            } else {
                // Scrolling DOWN
                nav.classList.add('is-hidden');
                nav.classList.remove('is-visible');
            }
            scrollPos = windowY;
            }

            window.addEventListener('scroll', checkPosition);
        //script de interactJs 
        interact('.draggable')
        .resizable({
            //margin: 60,
            // resize from all edges and corners
            edges: { left: false, right: true, bottom: true, top: false },

            listeners: {
            move (event) {
                var target = event.target
                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                // update the element's style
                target.style.width = event.rect.width + 'px'
                target.style.height = event.rect.width + 'px'

                // translate when resizing from top or left edges
                //x += event.deltaRect.left
                //y += event.deltaRect.top

                //target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

                //target.setAttribute('data-x', x)
                //target.setAttribute('data-y', y)
                //target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
            }
            },
            modifiers: [
            // keep the edges inside the parent
            interact.modifiers.restrictEdges({
                outer: 'parent'
            }),

            // minimum size
            interact.modifiers.restrictSize({
                max: { width: anchoMinimo, height: altoMinimo },
                min: {width: 60, height: 60}
            })
            ],

            inertia: true
        })
        .draggable({
            listeners: { move: dragMoveListener },
            inertia: true,
            modifiers: [
            interact.modifiers.restrictRect({
                restriction: 'parent',
                endOnly: true
            })
            ]
        })
        function dragMoveListener (event) {
        var target = event.target
        // keep the dragged position in the data-x/data-y attributes
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

        // translate the element
        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

        // update the posiion attributes
        target.setAttribute('data-x', x)
        target.setAttribute('data-y', y)
        }

        interact.addDocument(window.document, {
        events: { passive: false },
        });
        }
    }
    
</script>


<style scoped>
    .iconoResize{
        -webkit-text-fill-color: #00FF9D; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
    }
    .is-visible{
        display: inline;
    }
    .is-hidden{
        display: none;
    }
    .nomovible{
        touch-action: none; 
        user-select: none;
    }
    .pointer{
        cursor: pointer;
    }
    .elbtn{
        position: relative;
        left: 0%;
        bottom: 0px;
    }
    .elbtn2{
        position: absolute;
        left: 10%;
        bottom: 10px;
    }
    .sticker{
        width: 20.82%;
        aspect-ratio: 1;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        touch-action: none;
        user-select: none;
    }
    .contenedor{
        position:relative;
        width: 100%;
        height: 100%;
        padding-bottom: 56.25%;
    }
    .contenedor2{
        position:relative;
        width: 100%;
        height: 100%;
        padding-top: 177.7%;
    }
    .file-select {
    position: relative;
    display: inline-block;
    }

    .file-select::before {
    background-color: #000;
    border: #00FF9D solid 2px ;
    color: #00FF9D;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    content: 'Seleccionar'; /* testo por defecto */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    }

    .file-select input[type="file"] {
    opacity: 0;
    width: 270px;
    height: 40px;
    display: inline-block;
    }

    #src-file1::before {
    content: 'SUBIR OTRA FOTO';
    }
    .bordeado{
        border: #00FF9D solid 2px ;
        background-color: black;
    }
    .transp{
        background-color: rgba(0,0,0,0) !important;
    }
    .textoR{
            font-size: 1vw;
            float:right;
        }
    .img1{
        height: 100%;
        background-repeat:repeat-x;
        animation: 25s infinite linear MOVE-BG-BOT;
        background-image: url('/text_arriba2.png');
        background-size: auto 30px;
    }
    .tam{
        max-height: 600px;
    }
    .v-application .white--text {
        font-size: 14px!important;
    }
    @media (max-width: 960px){
        .img2{
            height: 100%;
            background-repeat:repeat-x;
            animation: 25s infinite linear MOVE-BG-TOP;
            background-image: url('/text_arriba2.png');
            background-size: auto 30px;
        }
        .img22{
            height: 100%;
            background-repeat:repeat-x;
            animation: 25s infinite linear MOVE-BG-TOP;
            background-image: url('/text_arriba2.png');
            background-size: auto 30px;
            margin-bottom: 2%;
        }
        .img1 {
            height: 100%;
            background-repeat:repeat-x;
            animation: 25s infinite linear MOVE-BG-BOT;
            background-image: url('/text_arriba2.png');
            background-size: auto 30px;
            margin-top: 2%;
        }
            .sticker{
            width: 37%;
            aspect-ratio: 1;
            position:absolute;
            top: 0;
            left: 0;
            background-position: center;
            background-size: cover;
            touch-action: none;
            user-select: none;
        }
        .textoR{
            font-size: 8pt;
        } 
        

        .tam{
            max-height: 650px;
            max-width: 373px;
        }
        .elbtn{
            position: relative;
            left: 0%;
            bottom: 0px;
        }
        .elbtn2{
            position: absolute;
            left: 6%;
            bottom: 10px;
        }
        .v-application .white--text {
            font-size: 9px!important;
        }
    }
    @media (min-width: 1264px) {
        .img1 {
            height: 100%;
            background-repeat:repeat-x;
            animation: 25s infinite linear MOVE-BG-BOT;
            background-image: url('/text_arriba2.png');
            background-size: auto 30px;
        }
        .elbtn{
            position: relative;
            left: 0%;
            bottom: 0px;
        }
        .elbtn2{
            position: absolute;
            left: 7%;
            bottom: 10px;
        }
        .v-application .white--text {
            font-size: 11px!important;
        }
    }
    .img2{
        height: 100%;
        background-repeat:repeat-x;
        animation: 25s infinite linear MOVE-BG-TOP;
        background-image: url('/text_arriba2.png');
        background-size: auto 30px;
    }
    .img22{
        height: 100%;
        background-repeat:repeat-x;
        animation: 25s infinite linear MOVE-BG-TOP;
        background-image: url('/text_arriba2.png');
        background-size: auto 30px;
        margin-bottom: 2%;
    }
    .img3{
        width: 100%;
        height: 100%;
        animation: 15s infinite linear MOVE-BG-LEF;
        background-image: url('/text_izquierda2.png');
        background-size: 30px;
    }
    .img4{
        width: 100%;
        height: 100%;
        animation: 15s infinite linear MOVE-BG-RIG;
        background-image: url('/text_drecha2.png');
        background-size: 30px;
    }
    @keyframes MOVE-BG-RIG {
            from {
                background-position: 0% 100%;
            }
            to { 
                
                background-position: 0% 0%;
            }
        }
    @keyframes MOVE-BG-LEF {
            from {
                background-position: 100% 0%;
            }
            to { 
                
                background-position: 100% 100%;
            }
        }
    @keyframes MOVE-BG-TOP {
        from {
            background-position: 0% 0%;
        }
        to { 
            
            background-position: 100% 0%;
        }
    }
    @keyframes MOVE-BG-BOT {
        from {
            background-position:100% 100%;
        }
        to { 
            
            background-position: 0% 100%;
        }
    }
</style>
